<template>
    <v-layout justify-center>
        <v-flex xs12 sm8 md4>
            <v-card flat>
                <v-card-title class="justify-center subheading pa-0"><b>{{ $t("pendingrecord.title")}}</b></v-card-title>
                    <v-layout row wrap class="mb-5">
                        <v-flex xs12 v-for="box_id in boxIds" :key="box_id">
                            <v-list subheader>
                                <v-subheader>{{ packages.find((a) => a.box_id === box_id).box_name }}({{ box_id }})</v-subheader>
                                <v-list-tile v-for="pdata in packages.filter((a) => a.box_id === box_id)" :key="pdata.track_no">
                                    <v-list-tile-content>
                                        <v-list-tile-title>{{ $t("pendingrecord.cell") }} {{ pdata.door_id }}</v-list-tile-title>
                                        <v-list-tile-sub-title>{{ $t("pendingrecord.put-in-time") }}: {{ pdata.putin_time || "" }}</v-list-tile-sub-title>
                                    </v-list-tile-content>
                                </v-list-tile>
                            </v-list>
                        </v-flex>
                    </v-layout>
            </v-card>
            <div>
                <v-btn block class="subheading" color="primary" @click="leave()">{{ $t("putIn.close") }}</v-btn>
            </div>
            <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from 'axios'
import store, { HostUrl } from "@/store"
import { getAxiosConfig } from '@/utils/AuthService'
import i18n from '@/i18n'

import WaitingDialog from "@/components/WaitingDialog"
import WaitDialogControl from "@/mixins/WaitDialogControl"

export default {
    name: 'pending-record',
    components: { WaitingDialog },
    mixins: [WaitDialogControl],
    data() {
        return {
            titles: [i18n.t("menu.buttons.pending-list")],
            headers:[
                {
                    sortable: false,
                    width: "10px",
                },
                { text: i18n.t("pendingrecord.box-id"), value: "box_id" },
                { text: i18n.t("pendingrecord.box-name"), value: "box_name"},
                { text: i18n.t("pendingrecord.cell"), value: "door_id" },
                { text: i18n.t("pendingrecord.put-in-time"), value: "putin_time" }
            ],
            packages:[]
        }
    },
    computed: {
        boxIds() {
            if (this.packages.length === 0) {
                return [];
            }
            const map = new Map();
            for (const item of this.packages) {
                if (!map.has(item.box_id)) {
                    map.set(item.box_id, true); // set any value to Map
                }
            }
            console.log(map.keys());
            return Array.from(map.keys());
        },
    },
    methods: {
        async getPendingRecord() {
            if (store.getters.user.id === 0) {
                return;
            }
            this.showDialog(i18n.t('pendingrecord.get-retrieve-package'), i18n.t('pendingrecord.fail-get-package'));
            let self = this;
            await axios.get(HostUrl + '/api/box/pending/reocrd', getAxiosConfig())
                .then(function (response) {
                console.log(response);
                const packages = response.data.packages;
                let packageArray = [];
                for (const track_no in packages) {
                        const { box_id, box_name, door_id, putin_time } = packages[track_no];
                        packageArray.push({
                            track_no,
                            box_id,
                            box_name,
                            door_id,
                            putin_time,
                        });
                    }
                    self.packages = packageArray;
                    self.closeDialog();
                })
                .catch(function(error) {
                    console.log("error", error);
                });
        },
        leave() {
            this.$router.push(`/${i18n.locale}/menu`);
        },
    },
    activated() {
        this.getPendingRecord();
    }
};
</script>

<style scoped>
.nobull {
    list-style-type: none;
    padding-left: 0px;
}
.bold {
    font-weight: bold;
    font-size: 1.25rem;
}
</style>